.hidden{
    display: none;
}

.logo{
    color: black;
    font-size: 1.2rem;
    cursor: pointer;
}

#nomination a{
    color: white;
    text-decoration: none;
}

canvas{
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(1.3);
    top: 100px;
}

#pdf-controls{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    background-color: rgba(0, 0, 0, 0.733);
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#pdf-controls p{
    margin-bottom: 0.5rem;
}

#pdf-buttons{
    display: flex;
}

#pdf-buttons button{
    margin: 0 5px;
}